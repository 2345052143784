import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import type {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {createDeliverySectionController, DeliverySectionController} from '@wix/delivery-section-checkout/controller';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {DeliveryStoreProps} from '../../../types/checkoutApp.types';
import {DeliverySectionService} from '../../services/checkout/DeliverySectionService';
import {ComponentState} from '@wix/delivery-section-checkout/types';

export type DeliveryStoreConfig = {
  flowAPI: ControllerFlowAPI;
  controllerConfig: IWidgetControllerConfig;
  checkoutService: CheckoutService;
  updateComponent: () => void;
  deliverySectionService: DeliverySectionService;
};

export class DeliveryStore {
  private readonly flowApi: ControllerFlowAPI;
  private readonly controllerConfig: IWidgetControllerConfig;
  private deliverySectionController!: DeliverySectionController;
  private readonly checkoutService!: CheckoutService;
  private readonly updateComponent: () => void;
  private readonly deliverySectionService: DeliverySectionService;

  constructor({
    flowAPI,
    controllerConfig,
    checkoutService,
    updateComponent,
    deliverySectionService,
  }: DeliveryStoreConfig) {
    this.flowApi = flowAPI;
    this.controllerConfig = controllerConfig;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.deliverySectionService = deliverySectionService;
  }

  public readonly init = () => {
    this.setDeliveryController();
    void this.deliverySectionController.init();
  };

  private readonly updateComponentState = (state: ComponentState) => {
    this.deliverySectionService.updateComponentState(state);
    this.updateComponent();
  };

  private readonly setDeliveryMethodId = async (id: string) => {
    await this.checkoutService.setShippingOption(id);
    this.updateComponent();
  };

  private readonly setDeliveryController = () => {
    this.deliverySectionController = createDeliverySectionController({
      flowAPI: this.flowApi,
      shouldShowOnlyPickup: false,
      controllerConfig: this.controllerConfig,
      getCheckoutV1: () => Promise.resolve(this.checkoutService.sdkCheckoutForDeliverySection!),
      setDeliveryMethodId: (id: string) => this.setDeliveryMethodId(id),
      onStateChanged: (state: ComponentState) => Promise.resolve(this.updateComponentState(state)),
    });
  };

  public toProps(): DeliveryStoreProps {
    return {
      hasSelectedOption: !!this.checkoutService.checkout.selectedShippingOption,
      componentState: this.deliverySectionService.componentState,
    };
  }
}
